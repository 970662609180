import Helpers from "../../../modules/Core/Helpers/Helpers";
import BreezeCollectiveApi from "../../Api/BreezeCollective";
import Service from "../Service";

class Item extends Service {
  getAll(type, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      BreezeCollectiveApi.items(account, repository, type, {
        params,
      }).then((r) => {
        const result = this.evaluate(r, `${type}s`);

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getOne(type, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      slug = "",
    } = options || {};

    return new Promise((resolve, reject) => {
      BreezeCollectiveApi.item(account, repository, type, slug).then((r) => {
        const result = this.evaluate(r, type);
        if (result) {
          resolve({ service: this, response: r });
        } else {
          reject(this);
        }
      });
    });
  }

  inquiry(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.get("default_repository"),
    } = options || {};

    return new Promise((resolve, reject) => {
      BreezeCollectiveApi.inquiry(account, repository, data).then((r) => {
        if (this.evaluate(r, "inquiry")) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Item();
