import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/footer/slider";
import Services from "../../Services/Services";

export default class Slider extends Component {
  id = "slider";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getItems();

    let buttons = document.getElementsByClassName("MuiTabScrollButton-root");
    let nav = document.querySelector(".MuiTabs-scroller");

    if (buttons.length === 2) {
      let left = buttons[0];
      let right = buttons[1];

      let idx;

      left.addEventListener("mouseenter", function () {
        idx = setInterval(() => (nav.scrollLeft -= 1), 5);
      });

      left.addEventListener("mouseleave", () => {
        clearInterval(idx);
      });

      right.addEventListener("mouseenter", () => {
        idx = setInterval(() => (nav.scrollLeft += 1), 5);
      });

      right.addEventListener("mouseleave", () => {
        clearInterval(idx);
      });
    }
  }

  getItems() {
    Services.get("item").then(([itemService]) => {
      let { pathname } = window.location;
      pathname = pathname.split("/");
      const slug = pathname[1].replace("breeze", "");

      itemService
        .getAll(slug, {
          params: {
            orderBy: "position",
            orderType: "asc",
            amount: 30,
          },
        })
        .then((itemService) => {
          const items = itemService.getData(`${slug}s`);
          this.setData({
            "default.items": items.rows,
            "default.p_slug": slug,
            "default.activeSlide": 0,
          });
          this.getComponents()
            .findById(`${slug}breeze-info`)
            .forEach((comp) => {
              comp.setData({
                "default.items": items.rows,
                "default.p_slug": slug,
                "default.activeSlide": 0,
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  setItem(index, slug) {
    this.getComponents()
      .findById(`${slug}breeze-info`)
      .forEach((comp) => {
        comp.setData({ "default.activeSlide": index });
      });

    const slider = document.getElementById(`custom-slider`);
    const slide = document.getElementById(`slide-${index}`);
    window.scrollTo(0, 0);
    slider.scrollTop = 0;
    slide.scrollTop = 0;
  }

  appleEffect(index) {
    const activeSlide = this.getData("default.activeSlide", 0);
    const titles = document.querySelectorAll(".title");

    const el_to_click = activeSlide > index ? index - 1 : index + 1;

    if (index > 0 && index < titles.length - 1) {
      document.getElementById(`tab-${el_to_click}`).click();
    } else {
      document.getElementById(`tab-${index}`).click();
    }

    titles.forEach((title, i) => {
      title.style.display = i !== index ? "none" : "block";
    });

    const images = document.querySelectorAll(".MuiTabs-flexContainer img");
    images[index].style.transform = "scale(1.7)";
    images[index].style.WebkitTransition = "scale(1.7)";

    var scales = [1.3, 1.1];
    for (let i = 1; i <= 2; i++) {
      if (index - i >= 0) {
        images[index - i].style.transform = "scale(" + scales[i - 1] + ")";
        images[index - i].style.WebkitTransition =
          "scale(" + scales[i - 1] + ")";
      }
      if (index + i < images.length) {
        images[index + i].style.transform = "scale(" + scales[i - 1] + ")";
        images[index + i].style.WebkitTransition =
          "scale(" + scales[i - 1] + ")";
      }
    }

    this.setData({
      "default.activeSlide": index,
    });
  }

  removeAppleEffect() {
    const images = document.querySelectorAll(".MuiTabs-flexContainer img");
    images.forEach((img) => {
      img.style.transform = "scale(1)";
      img.style.WebkitTransition = "scale(1)";
    });
  }
}
