import React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import GetInTouch from "../../../../Components/Widgets/Dialogs/GetInTouch";

const StyledToolbar = styled(Toolbar)({
  backgroundColor: "#141416",
  height: 80,
  minHeight: "initial",
  padding: "0px 3.5963479809976246% !important",
});

const GetInTouchBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 400,
  fontSize: 16,
  height: 40,
  width: 138,
  color: "#fff",
  borderRadius: 50,
  fontFamily: "Arial,Helvetica,sans-serif",
  "&:hover": { backgroundColor: "#404349" },
  "@media (max-width: 320px )": { width: 128 },
}));

const Logo = styled("img")(({ theme }) => ({
  height: 42,
  "@media (max-width: 320px )": { height: 32 },
}));

export default function Nav(props) {
  const { component } = props;
  const { color = "", position = "static" } = component.props;

  const [get_in_touch, setOpen] = React.useState(false);

  const { apple = false } = component.getData("default", {});

  return (
    <AppBar position={position}>
      <StyledToolbar>
        <Button
          style={{
            color: "#B6C9D2",
            padding: 0,
            minWidth: "unset",
          }}
          onClick={() => {
            component.toggleMenuDrawer();
          }}
          disableRipple
        >
          <i
            className="fa-light fa-bars"
            style={{ fontSize: 20, transform: "scale(1.4,1.2)" }}
          />
        </Button>

        <Link
          component={RouterLink}
          to="/"
          style={{ marginLeft: "3.5963479809976246%" }}
        >
          <Logo
            alt="Home | Breeze Collective"
            src="/images/logos/main_logo.webp"
            title="Home | Breeze Collective"
          />
        </Link>
        <div style={{ flexGrow: 1 }}></div>
        <GetInTouchBtn
          disableRipple
          onClick={() => setOpen(true)}
          sx={{ backgroundColor: color !== "" ? color : "rgb(145, 145, 145)" }}
        >
          {component.ucfirst("get-in-touch-btn")}
        </GetInTouchBtn>
      </StyledToolbar>
      <GetInTouch
        {...{
          ...props,
          ...{
            open: get_in_touch,
            apple,
            setOpen: (boolean) => setOpen(boolean),
          },
        }}
      />
    </AppBar>
  );
}
