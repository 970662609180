import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/get_in_touch";
import Services from "../../../Services/Services";

export default class GetInTouch extends Component {
  id = "get-in-touch";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.resetForm();
  }

  resetForm() {
    this.setData({
      "default.form": {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        inquiry: "",
        you_are: [],
        you_are_interested_in: [],
      },
    });
  }

  sendInquiry(e) {
    e.preventDefault();

    const { form } = this.getData("default", {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      inquiry: "",
      you_are: [],
      you_are_interested_in: [],
    });

    const error = {
      form: this.getHelpers("validate").validate(form, {
        first_name: [
          {
            rule: "required",
            message: this.ucfirst("first_name-helpertext"),
          },
        ],
        last_name: [
          {
            rule: "required",
            message: this.ucfirst("last_name-helpertext"),
          },
        ],
        email: [
          {
            rule: "required",
            message: this.ucfirst("email-helpertext"),
          },
          {
            rule: "email",
            message: this.ucfirst("email-helpertext-2"),
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
        inquiry: [
          {
            rule: "required",
            message: this.ucfirst("inquiry-helpertext"),
          },
        ],
      }),
    };

    this.setData({
      error,
    });

    if (!error.form) {
      const you_are = document.querySelectorAll(".you_are label");
      form["you_are"] = [];
      var childs;
      for (let i = 0; i < you_are.length; i++) {
        childs = you_are[i].querySelectorAll("span");

        if (childs[0].querySelector("input").checked) {
          form["you_are"].push(childs[2].innerHTML);
        }
      }

      const you_are_interested_in = document.querySelectorAll(
        ".you_are_interested_in label"
      );
      form["you_are_interested_in"] = [];
      for (let i = 0; i < you_are_interested_in.length; i++) {
        childs = you_are_interested_in[i].querySelectorAll("span");

        if (childs[0].querySelector("input").checked) {
          form["you_are_interested_in"].push(
            childs[3].innerHTML + childs[4].innerHTML
          );
        }
      }

      // console.log(form);
      Services.get("user").then(([userService]) => {
        userService
          .inquiry(form)
          .then(() => {
            this.resetForm();
            this.props.setOpen(false);
          })
          .catch((err) => {
            console.log("error", err);
          });
      });
    }
  }
}
