import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Typography, Grid, Box } from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";
import SwipeableViews from "react-swipeable-views";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 22.5,
  fontWeight: 600,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
  margin: "10px 0 20px",
  textTransform: "uppercase",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  fontWeight: 200,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
}));

export default function Info(props) {
  const theme = useTheme();
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const files_v = component.getHelpers("value").getValue("files-version");
  const {
    items = [],
    activeSlide = 0,
    p_slug,
  } = component.getData("default", {});

  const handleSlideChange = (index) => {
    component.setData({ "default.activeSlide": index });

    component
      .getComponents()
      .findById("slider")
      .forEach((comp) => {
        comp.appleEffect(index);
      });

    const slider = document.getElementById(`custom-slider`);
    const slide = document.getElementById(`slide-${index}`);
    window.scrollTo(0, 0);
    slider.scrollTop = 0;
    slide.scrollTop = 0;
  };

  return (
    <>
      {items.length !== 0 && (
        <Box
          id="info_section"
          sx={{
            width: "100%",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeSlide}
            onChangeIndex={handleSlideChange}
            enableMouseEvents
            id="custom-slider"
            className="hidden-scrollbar"
            autoheight="true"
          >
            {items.map((item, index) => {
              const { slug = null, name = null } = devHelper.getObjectValue(
                item,
                "_values",
                {}
              );
              return (
                <Box
                  key={slug}
                  id={`slide-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    height: "100vh",
                    overflow: "auto",
                    pt: { xs: "120px", md: "200px", lg: "300px" },
                    pb: "160px",
                  }}
                >
                  <Box
                    sx={{
                      margin: { xs: 0, md: "0 auto" },
                      maxWidth: 1000,
                      px: { xs: "20px", md: 0 },
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      sx={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      spacing={0}
                    >
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: { xs: "none", md: "block" },
                        }}
                      >
                        <img
                          alt={`${name} white icon`}
                          src={`${filesUrl}/images/icons/white/${slug}.svg?v=${files_v}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Box
                          sx={{
                            width: "95%",
                            "@media (max-width: 959px )": {
                              margin: 0,
                            },
                          }}
                        >
                          <Title>{component.trans(name)}</Title>
                          <Text>
                            <Translate
                              entity="item"
                              eslug={slug}
                              scope="default"
                              slug={p_slug}
                              auto
                            />
                          </Text>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              );
            })}
          </SwipeableViews>
        </Box>
      )}
    </>
  );
}
