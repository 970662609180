import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Dialog,
  DialogContent,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 27,
  fontWeight: 600,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  textAlign: "center",
  color: "#fff",
  textTransform: "uppercase",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
  textTransform: "uppercase",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  fontWeight: 200,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
  textAlign: "center",
  margin: "10px 0",
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  "& label": {
    color: "#fff",
  },
  "&:hover label": {
    // fontWeight: 700
  },
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    transform: "unset",
    borderBottom: "1px solid #fff",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #fff",
  },
  "& .MuiInput-root": {
    color: "#fff",
    "&:hover": {
      borderBottomColor: "#fff",
    },
    "& fieldset": {
      border: "1px solid #fff",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
      borderWidth: 1,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  float: "right",
  border: "1px solid #B6C9D2",
  backgroundColor: "#B6C9D2",
  color: "#fff",
  marginTop: 10,
  "&:hover": {
    backgroundColor: "#B6C9D2",
  },
}));
const MyFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: "#fff",
  display: "flex",
  "& .MuiTypography-root": {
    fontSize: 14,
    fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  },
}));

const LogoFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: "#fff",
  display: "flex",
  "& .MuiTypography-root": {
    fontSize: 18,
    fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  },
}));

const InnerGrid = styled(Grid)(({ theme }) => ({
  paddingTop: 0,
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 0,
  width: 16,
  height: 16,
  border: "1px solid #fff",
  backgroundColor: theme.palette.mode === "transparent",

  backgroundImage: theme.palette.mode === "transparent",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 14,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function GetInTouchDialog(props) {
  const { component } = props;

  const { open = false, apple = false } = component.props;
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Dialog
      onClose={() => component.props.setOpen(false)}
      open={open}
      fullScreen={fullScreen}
      sx={{
        "& .MuiDialogContent-root": {
          padding: "10px 20px",
          backgroundColor: "rgb(20, 20, 22 ,0.9)",
        },
        "& .MuiPaper-rounded": {
          borderRadius: 0,
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogContent
        style={{ position: "relative", overflowY: "scroll" }}
        className="mydialog"
      >
        <IconButton
          onClick={() => component.props.setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <i className="fa-sharp fa-light fa-xmark" />
        </IconButton>
        <Title>{component.ucfirst("get-in-touch")}</Title>
        <Text>{component.trans("contact-description")}</Text>
        <form onSubmit={(e) => component.sendInquiry(e)}>
          <SubTitle>{component.trans("contact-sub-title-1")}</SubTitle>
          <Grid
            container
            justifycontent="center"
            spacing={0}
            className="you_are"
          >
            <InnerGrid item sm={6} xs={12}>
              <MyFormControlLabel
                label={component.trans("contact-checkbox-label-1")}
                control={<BpCheckbox />}
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "10px" : 0,
                  },
                }}
              />
              <MyFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "10px" : 0,
                  },
                }}
                label={component.trans("contact-checkbox-label-2")}
                control={<BpCheckbox />}
              />

              <MyFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "10px" : 0,
                  },
                }}
                label={component.trans("contact-checkbox-label-3")}
                control={<BpCheckbox />}
              />
            </InnerGrid>
            <InnerGrid item sm={6} xs={12}>
              <MyFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "10px" : 0,
                  },
                }}
                label={component.trans("contact-checkbox-label-4")}
                control={<BpCheckbox />}
              />
              <MyFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "10px" : 0,
                  },
                }}
                label={component.trans("contact-checkbox-label-5")}
                control={<BpCheckbox />}
              />
              <MyFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "10px" : 0,
                  },
                }}
                label={component.trans("contact-checkbox-label-6")}
                control={<BpCheckbox />}
              />
            </InnerGrid>
          </Grid>
          <SubTitle>{component.trans("contact-sub-title-2")}</SubTitle>
          <Grid
            container
            justifycontent="center"
            spacing={0}
            className="you_are_interested_in"
          >
            <InnerGrid item sm={6} xs={12}>
              <LogoFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "5px" : 0,
                    height: apple ? "18px" : "unset",
                  },
                }}
                label={
                  <>
                    <span style={{ color: "#B78140" }}>Agro</span>
                    <span style={{ color: "#B6C9D2" }}>BREEZE</span>
                  </>
                }
                control={<BpCheckbox />}
              />
              <LogoFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "5px" : 0,
                    height: apple ? "18px" : "unset",
                  },
                }}
                label={
                  <>
                    <span style={{ color: "#009444" }}>Canna</span>
                    <span style={{ color: "#B6C9D2" }}>BREEZE</span>
                  </>
                }
                control={<BpCheckbox />}
              />
            </InnerGrid>
            <InnerGrid item sm={6} xs={12}>
              <LogoFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "5px" : 0,
                    height: apple ? "18px" : "unset",
                  },
                }}
                label={
                  <>
                    <span style={{ color: "#8DC63F" }}>Pharma</span>
                    <span style={{ color: "#B6C9D2" }}>BREEZE</span>
                  </>
                }
                control={<BpCheckbox />}
              />
              <LogoFormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    marginTop: apple ? "5px" : 0,
                    height: apple ? "18px" : "unset",
                  },
                }}
                label={
                  <>
                    <span style={{ color: "#B3B2B7" }}>Tech</span>
                    <span style={{ color: "#B6C9D2" }}>BREEZE</span>
                  </>
                }
                control={<BpCheckbox />}
              />
            </InnerGrid>
          </Grid>
          <Grid container justifycontent="center" spacing={1}>
            <InnerGrid item sm={6} xs={12}>
              <MyTextField
                variant="standard"
                margin="normal"
                fullWidth
                label={component.ucfirst("textfield-firstname")}
                name="first_name"
                helperText={
                  component.getData("error.form.first_name", [
                    { message: "" },
                  ])[0].message
                }
                value={component.getData("default.form.first_name", "")}
                onChange={(e) => {
                  console.log(component.getData("default.form.first_name", ""));
                  component.setData({
                    "default.form.first_name": e.target.value,
                  });
                  component.deleteData("error.form.first_name");
                }}
                error={component.dataExists("error.form.first_name")}
              />
            </InnerGrid>
            <InnerGrid item sm={6} xs={12}>
              <MyTextField
                variant="standard"
                margin="normal"
                fullWidth
                label={component.ucfirst("textfield-lastname")}
                name="last_name"
                helperText={
                  component.getData("error.form.last_name", [
                    { message: "" },
                  ])[0].message
                }
                value={component.getData("default.form.last_name", "")}
                onChange={(e) => {
                  component.setData({
                    "default.form.last_name": e.target.value,
                  });
                  component.deleteData("error.form.last_name");
                }}
                error={component.dataExists("error.form.last_name")}
              />
            </InnerGrid>
            <InnerGrid item sm={6} xs={12}>
              <MyTextField
                variant="standard"
                margin="normal"
                fullWidth
                label={component.ucfirst("textfield-email")}
                name="email"
                helperText={
                  component.getData("error.form.email", [{ message: "" }])[0]
                    .message
                }
                value={component.getData("default.form.email", "")}
                onChange={(e) => {
                  component.setData({
                    "default.form.email": e.target.value,
                  });
                  component.deleteData("error.form.email");
                }}
                error={component.dataExists("error.form.email")}
              />
            </InnerGrid>
            <InnerGrid item sm={6} xs={12}>
              <MyTextField
                variant="standard"
                margin="normal"
                fullWidth
                label={component.ucfirst("textfield-phone")}
                name="phone"
                helperText={
                  component.getData("error.form.phone", [{ message: "" }])[0]
                    .message
                }
                value={component.getData("default.form.phone", "")}
                onChange={(e) => {
                  component.setData({
                    "default.form.phone": e.target.value,
                  });
                  component.deleteData("error.form.phone");
                }}
                error={component.dataExists("error.form.phone")}
              />
            </InnerGrid>
            <InnerGrid item xs={12}>
              <MyTextField
                variant="standard"
                margin="normal"
                fullWidth
                label={component.ucfirst("textfield-inquiry")}
                name="inquiry"
                helperText={
                  component.getData("error.form.inquiry", [{ message: "" }])[0]
                    .message
                }
                value={component.getData("default.form.inquiry", "")}
                onChange={(e) => {
                  component.setData({
                    "default.form.inquiry": e.target.value,
                  });
                  component.deleteData("error.form.inquiry");
                }}
                error={component.dataExists("error.form.inquiry")}
              />
            </InnerGrid>
          </Grid>
          <SubmitButton type="submit">
            {component.trans("contact-send-btn")}
          </SubmitButton>
        </form>
      </DialogContent>
    </Dialog>
  );
}
