import React from "react";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import Fade from "@mui/material/Fade";

const StyledImg = styled("img")`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
`;

const StyledDiv = styled("div")`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -3;
  background-color: #141416;
`;

const StyledVideo = styled("video")`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

export default function MainBanner(props) {
  const { component } = props;
  const {
    slug,
    videos = [],
    showVideo = false,
    showImg = false,
  } = component.getData("default", {});

  return (
    <>
      <StyledDiv></StyledDiv>
      {videos
        .filter((videos) => videos.slug === slug)
        .map((video) => {
          return (
            <Fade
              key={`image-${video.slug}`}
              in={showImg}
              timeout={slug === "breezecollective" ? 3000 : 0}
            >
              <StyledImg component="img" src={video.image} />
            </Fade>
          );
        })}

      {!isMobile &&
        videos
          .filter((videos) => videos.video !== "")
          .map((video) => {
            return (
              <StyledVideo
                key={`video-${video.slug}`}
                src={video.video}
                autoPlay
                muted
                loop
                sx={{
                  opacity:
                    video.loaded && video.slug === slug && showVideo && showImg
                      ? 1
                      : 0,
                }}
                onLoadedData={() => {
                  video.loaded = true;
                  component.setData({ "default.videos": videos });
                }}
              />
            );
          })}
    </>
  );
}
