import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/navbar";

export default class Navbar extends Component {
  id = "navbar";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    if (
      window.navigator.platform.indexOf("Mac") === 0 ||
      window.navigator.platform === "iPhone" ||
      window.navigator.platform === "iPad"
    ) {
      this.setData({
        "default.apple": true,
      });
    } else {
      this.setData({
        "default.apple": false,
      });
    }
  }

  toggleMenuDrawer() {
    this.getComponents()
      .findById("menu-drawer")
      .forEach((drawer) => {
        drawer.toggle();
      });
  }
}
