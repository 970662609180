import React from "react";
import { styled } from "@mui/material/styles";
import { Link, Container, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const StyledLink = styled(Link)({
  margin: 0,
  textAlign: "left",
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  lineHeight: 1,
  fontWeight: 200,
  fontSize: 15,
  color: "rgb(145, 145, 145)",
  display: "block",
  textDecoration: "none",
});

export default function Footer(props) {
  const { component } = props;

  return (
    <Box
      component="footer"
      sx={{
        // position: "fixed",
        // bottom: 0,
        py: { xs: 3 },
        px: { xs: 1, md: 2 },
        mt: "auto",
        width: "100%",
        backgroundColor: "#141416",
        height: 80,
      }}
    >
      <Container maxWidth="xl" sx={{ display: "flex" }}>
        <Box
          sx={{
            width: { xs: "100%", md: "unset" },
            flexGrow: { xs: 0, md: "1" },
          }}
        >
          <StyledLink
            component={RouterLink}
            to="/"
            sx={{
              fontSize: 15,
              marginTop: { xs: 0, md: "5px" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {component.trans("footer-company-name")}
          </StyledLink>
          <StyledLink
            sx={{
              fontSize: 13,
              cursor: "unset",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {component.trans("address")}
          </StyledLink>
          {/* <StyledLink
            sx={{
              fontSize: 13,
              cursor: "unset",
              marginTop: { xs: "5px" },
              textAlign: { xs: "center", md: "left" },
              display: { xs: "block", md: "none" },
            }}
          >
            {component.trans("Terms & Conditions")}
          </StyledLink> */}
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {/* <StyledLink
            sx={{
              fontSize: 13,
              cursor: "unset",
              marginTop: { xs: 0, md: "15px" },
            }}
          >
            {component.trans("Terms & Conditions")}
          </StyledLink> */}
        </Box>
      </Container>
    </Box>
  );
}
