import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

const StyledDiv = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: "20px 0",
  maxWidth: 800,
  textAlign: "center",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
  textTransform: "uppercase",
  margin: "0 0 5px",
  lineHeight: 1.4,
  letterSpacing: 0,
  "@media (max-width: 580px )": {
    fontSize: 26,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 26,
  fontWeight: 400,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
  margin: "0 auto 20px",
  display: "block",
  "@media (max-width: 580px )": {
    fontSize: 24,
    margin: "0 auto 10px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 15,
  padding: "8px 24px",
  color: "#fff",
  borderRadius: 50,
  backgroundColor: "#009444",
  fontFamily: "Arial,Helvetica,sans-serif",
  "&:hover": { backgroundColor: "#009444" },
  "&.Mui-disabled": {
    color: "#fff",
  },
}));

export default function FirstSection(props) {
  const { component } = props;

  return (
    <StyledDiv>
      <Title>{component.trans("cannabreeze-main-title")}</Title>
      <Text>
        <span
          dangerouslySetInnerHTML={{
            __html: component.trans("cannabreeze-secondary-text"),
          }}
        ></span>
      </Text>
      <StyledButton component={RouterLink} to="/cannabreeze/info" disableRipple>
        {component.trans("get-started-btn")}
      </StyledButton>
    </StyledDiv>
  );
}
