import React from "react";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const StyledDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
    flex: "50px",
    display: "flex",
    padding: "40px 40px 40px 0px",
    width: 260,
    backgroundColor: "rgb(20, 20, 22 ,0.9)",
    textAlign: "right",
    zIndex: 2,
    "@media (max-width: 640px)": { width: "90%" },
  },
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  position: "absolute",
  cursor: "pointer",
  right: 20,
  top: 20,
  fontSize: 35,
  color: "#C9C9C9",
  "&:hover": {
    opacity: 1,
  },
  "@media (max-width: 450px)": {
    fontSize: 30,
  },
}));

const StyledLinkLogo = styled(Link)(({ theme }) => ({
  margin: "5px 0",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  margin: "20px 10px 0 0 ",
  textTransform: "uppercase",
  textDecoration: "none",
  color: "#fff",
  fontSize: 14,
  fontWeight: 600,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
}));

export default function MenuDrawer(props) {
  const { component } = props;

  return (
    <StyledDrawer
      anchor="left"
      open={component.getData("default.opened", false)}
      onClose={() => component.toggle()}
      onOpen={() => {}}
      transitionDuration={{ enter: 500, exit: 500 }}
      swipeAreaWidth={0}
    >
      <StyledCloseIcon onClick={() => component.toggle()} />
      <StyledLinkLogo component={RouterLink} to="/agrobreeze">
        <img
          alt="logo"
          src="/images/logos/argo-breeze.webp"
          height={42}
          style={{
            width: 220,
            float: "right",
          }}
        />
      </StyledLinkLogo>
      <StyledLinkLogo component={RouterLink} to="/cannabreeze">
        <img
          alt="logo"
          src="/images/logos/canna-breeze.webp"
          height={42}
          style={{
            width: 220,
            float: "right",
          }}
        />
      </StyledLinkLogo>
      <StyledLinkLogo component={RouterLink} to="/pharmabreeze">
        <img
          alt="logo"
          src="/images/logos/pharma-breeze.webp"
          height={42}
          style={{
            width: 220,
            float: "right",
          }}
        />
      </StyledLinkLogo>
      <StyledLinkLogo component={RouterLink} to="/techbreeze">
        <img
          alt="logo"
          src="/images/logos/tech-breeze.webp"
          height={42}
          style={{
            width: 220,
            float: "right",
          }}
        />
      </StyledLinkLogo>
      <StyledLink component={RouterLink} to="/about">
        {component.ucfirst("about-us")}
      </StyledLink>
      <StyledLink component={RouterLink} to="/team">
        {component.ucfirst("our-team")}
      </StyledLink>
      {/* <StyledLink component={RouterLink} to="/faq">
        {component.ucfirst("f.a.q")}
      </StyledLink> */}
      <StyledLink component={RouterLink} to="/contact">
        {component.ucfirst("contact")}
      </StyledLink>
    </StyledDrawer>
  );
}
