import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const StyledDiv = styled("div")(({ theme }) => ({
  margin: "0 auto",
  maxWidth: 1000,
  "@media (max-width: 1020px )": {
    padding: "20px",
  },
}));

const Paragraph = styled("div")(({ theme }) => ({
  height: 81 * 3.5,
  display: "block",
  margin: "auto",
  // overflowY: "scroll",
  "@media (max-width: 959px )": {
    margin: 0,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 22.5,
  fontWeight: 600,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
  width: 120,
  marginLeft: -120,
  "@media (max-width: 1250px )": {
    marginLeft: 0,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  fontWeight: 200,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
}));

export default function firstSection(props) {
  const { component } = props;

  return (
    <StyledDiv>
      <Title>{component.ucfirst("about-us")}</Title>
      <Paragraph className="paragraph">
        <Text>
          <span
            dangerouslySetInnerHTML={{
              __html: component.trans("about-us-text"),
            }}
          ></span>
        </Text>
      </Paragraph>
    </StyledDiv>
  );
}
