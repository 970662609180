import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/video";

export default class Video extends Component {
  id = "video";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.videos": [
        {
          slug: "breezecollective",
          video:
            "https://www.dropbox.com/s/2nvvyu591rdkmun/breezecollective.mp4?raw=1",
          image: "/images/loading/breezecollective.jpg",
          loaded: false,
        },
        {
          slug: "cannabreeze",
          video:
            "https://www.dropbox.com/s/idarxfykm25uvng/cannabreeze.mp4?raw=1",
          image: "/images/loading/cannabreeze.jpg",
          loaded: false,
        },
        {
          slug: "agrobreeze",
          video:
            "https://www.dropbox.com/s/rkgb6v3wjg01nrs/agrobreeze.mp4?raw=1",
          image: "/images/loading/agrobreeze.jpg",
          loaded: false,
        },
        {
          slug: "pharmabreeze",
          video:
            "https://www.dropbox.com/s/zoff9xgfno948gw/pharmabreeze.mp4?raw=1",
          image: "/images/loading/pharmabreeze.jpg",
          loaded: false,
        },
        {
          slug: "techbreeze",
          video:
            "https://www.dropbox.com/s/kr9vnsts6nlhcib/techbreeze.mp4?raw=1",
          image: "/images/loading/techbreeze.jpg",
          loaded: false,
        },
        {
          slug: "about",
          video: "",
          image: "/images/loading/breezecollective.jpg",
          loaded: false,
        },
        {
          slug: "team",
          video: "",
          image: "/images/loading/breezecollective.jpg",
          loaded: false,
        },
        {
          slug: "faq",
          video: "",
          image: "/images/loading/breezecollective.jpg",
          loaded: false,
        },
        {
          slug: "contact",
          video: "",
          image: "/images/loading/breezecollective.jpg",
          loaded: false,
        },
      ],
    });

    this.showVideo();
  }

  showVideo() {
    let { pathname } = window.location;
    pathname = pathname.split("/");
    const slug = pathname.length >= 2 ? pathname[1] : "";

    let showVideo = true;

    if (
      pathname.length >= 2 &&
      (pathname[1] === "about" ||
        pathname[1] === "team" ||
        pathname[1] === "faq" ||
        pathname[1] === "contact" ||
        pathname.length === 3)
    ) {
      showVideo = false;
    }

    this.setData({
      "default.showVideo": showVideo,
      "default.slug": slug ? slug : "breezecollective",
    });

    if (slug) {
      this.setData({
        "default.showImg": true,
      });
    } else {
      setTimeout(() => {
        this.setData({
          "default.showImg": true,
        });
      }, 1000);
    }
  }
}
