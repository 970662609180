import React from "react";
import { styled } from "@mui/material/styles";
import {
  CardMedia,
  Typography,
  Box,
  Container,
  Tabs,
  Tab,
} from "@mui/material";
import { isMobile } from "react-device-detect";

const StyledCardMedia = styled(CardMedia)({
  width: "71px",
  height: "73px",
  margin: 0,
  transformOrigin: "50% 100%",
  webkitTransformOrigin: "50% 100%",
  transition: "all 0.3s",
  webkitTransition: "all 0.3s",
  pointer: "cursor",
});

const StyledTypography = styled(Typography)({
  display: "none",
  position: "absolute",
  bottom: "105px",
  left: "-50%",
  width: "200%",
  padding: "4px 0",
  textTransform: "uppercase",
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  fontSize: 12,
  lineHeight: 1,
  fontWeight: 500,
  letterSpacing: "normal",
  color: "#fff",
  zIndex: 1,
  textAlign: "center",
});

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabScrollButton-root": {
    color: "#fff",
    marginTop: "80px",
    height: "80px",
    width: 40,
  },
  "& .MuiSvgIcon-fontSizeSmall": {
    fontSize: "30px",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    "&.MuiTab-root": {
      position: "relative",
      height: "160px",
      textAlign: "center",
      display: "flex !important",
      alignItems: "center",
      justifyContent: "flex-end !important",
      overflow: "visible !important",
      padding: "0 8px",
      "&:hover": {
        background: "transparent",
      },
    },
    "&.Mui-selected": {
      color: "#fff",
      border: "none",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function MySlider(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const files_v = component.getHelpers("value").getValue("files-version");

  const { items = [], p_slug } = component.getData("default", {});

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      component="footer"
      sx={{
        position: "fixed",
        bottom: 0,
        height: "160px",
        width: "100%",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: "0px !important",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#141416",
            height: "80px",
          }}
        ></div>
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={!isMobile}
          sx={{
            "& .MuiTabs-scrollButtons.Mui-disabled": {
              opacity: 0.5,
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
        >
          {items.map((item, index) => {
            const { slug = null, name = null } = devHelper.getObjectValue(
              item,
              "_values",
              {}
            );
            return (
              <StyledTab
                id={`tab-${index}`}
                key={slug}
                disableRipple
                label={
                  <Box
                    onMouseEnter={() => component.appleEffect(index)}
                    onMouseLeave={() => component.removeAppleEffect()}
                    onClick={() => component.setItem(index, p_slug)}
                    sx={{ position: "relative" }}
                  >
                    <StyledTypography className="title" id={`title-${index}`}>
                      {devHelper.getObjectValue(item, "_values.name")}
                    </StyledTypography>
                    <StyledCardMedia
                      className={"icons"}
                      component="img"
                      alt={`${name} green icon`}
                      src={`${filesUrl}/images/icons/${p_slug}breeze/${slug}.svg?v=${files_v}`}
                    />
                  </Box>
                }
              ></StyledTab>
            );
          })}
        </StyledTabs>
      </Container>
    </Box>
  );
}
