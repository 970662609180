import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/drawers/menu_drawer";

export default class MenuDrawer extends Component {
  id = "menu-drawer";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }

  toggle() {
    this.toggleData("default.opened", {
      defaultValue: true,
    });
  }
}
