import Home from "./Page/Home";
import AgroBreeze from "./Page/AgroBreeze";
import AgroBreezeInfo from "./Page/AgroBreezeInfo";
import CannaBreeze from "./Page/CannaBreeze";
import CannaBreezeInfo from "./Page/CannaBreezeInfo";
import PharmaBreeze from "./Page/PharmaBreeze";
import TechBreeze from "./Page/TechBreeze";
import AboutUs from "./Page/AboutUs";
import OurTeam from "./Page/OurTeam";
import Contact from "./Page/Contact";

// Redirect Section
import Redirect from "./Page/Redirect";

const Module = {
  Home,
  Redirect,
  AgroBreeze,
  AgroBreezeInfo,
  CannaBreeze,
  CannaBreezeInfo,
  PharmaBreeze,
  TechBreeze,
  AboutUs,
  OurTeam,
  Contact,
};

export default Module;
