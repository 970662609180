import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/home";

export default class Home extends Page {
  title = "home";
  id = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setWindowTitle("Breeze Collective");

    this.getComponents()
      .findById("video")
      .forEach((el) => {
        el.showVideo();
      });

    setTimeout(() => {
      this.setData({
        "default.showButtons": true,
      });
    }, 2000);

    this.scrollToTop();
  }
}
