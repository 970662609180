import React from "react";
import FirstSection from "../widgets/team/firstSection";
import Footer from "../widgets/footer/footer";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import { styled } from "@mui/material/styles";

const Page = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
}));

const MainContent = styled("div")(({ theme }) => ({
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  flex: "50px",
  display: "flex",
  overflow: "auto",
  backgroundColor: "rgb(20, 20, 22 ,0.8)",
}));

const ExportDefault = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <MenuDrawer {...props} />
      <Page>
        <div>
          <NavBar {...props} />
        </div>
        <MainContent>
          <FirstSection {...props} />
        </MainContent>
        <div>
          <Footer {...props} />
        </div>
      </Page>
    </div>
  );
};

export default ExportDefault;
