import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Pages from "../Components/Pages";
import Video from "../Components/Widgets/Video";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <Router>
        <CssBaseline />
        <Switch>
          <Route exact path="/" component={Pages.Home} />
          <Route exact path="/agrobreeze" component={Pages.AgroBreeze} />
          <Route
            exact
            path="/agrobreeze/info"
            component={Pages.AgroBreezeInfo}
          />
          <Route exact path="/cannabreeze" component={Pages.CannaBreeze} />
          <Route
            exact
            path="/cannabreeze/info"
            component={Pages.CannaBreezeInfo}
          />
          <Route exact path="/pharmabreeze" component={Pages.PharmaBreeze} />
          <Route exact path="/techbreeze" component={Pages.TechBreeze} />
          <Route exact path="/about" component={Pages.AboutUs} />
          <Route exact path="/team" component={Pages.OurTeam} />
          <Route exact path="/contact" component={Pages.Contact} />
          <Route component={Pages.Redirect} />
        </Switch>
        <Video
          {...{
            ...props,
            ...{ slug: "cannabreeze" },
          }}
        />
      </Router>
    )
  );
};

export default ExportDefault;
