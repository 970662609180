import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/contact";
import Services from "../../Services/Services";

export default class Contact extends Page {
  id = "contact";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setWindowTitle("Our Team | Breeze Collective");

    this.getComponents()
      .findById("video")
      .forEach((el) => {
        el.showVideo();
      });

    if (
      window.navigator.platform.indexOf("Mac") === 0 ||
      window.navigator.platform === "iPhone" ||
      window.navigator.platform === "iPad"
    ) {
      this.setData({
        "default.apple": true,
      });
    } else {
      this.setData({
        "default.apple": false,
      });
    }

    this.resetForm();

    this.scrollToTop();
  }

  resetForm() {
    this.setData({
      "default.form": {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        inquiry: "",
        you_are: [],
        you_are_interested_in: [],
      },
    });
  }

  sendInquiry(e) {
    e.preventDefault();

    const { form } = this.getData("default", {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      inquiry: "",
      you_are: [],
      you_are_interested_in: [],
    });

    const error = {
      form: this.getHelpers("validate").validate(form, {
        first_name: [
          {
            rule: "required",
            message: this.ucfirst("first_name-helpertext"),
          },
        ],
        last_name: [
          {
            rule: "required",
            message: this.ucfirst("last_name-helpertext"),
          },
        ],
        email: [
          {
            rule: "required",
            message: this.ucfirst("email-helpertext"),
          },
          {
            rule: "email",
            message: this.ucfirst("email-helpertext-2"),
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
        inquiry: [
          {
            rule: "required",
            message: this.ucfirst("inquiry-helpertext"),
          },
        ],
      }),
    };

    this.setData({
      error,
    });

    if (!error.form) {
      const you_are = document.querySelectorAll(".you_are label");
      form["you_are"] = [];
      var childs;
      for (let i = 0; i < you_are.length; i++) {
        childs = you_are[i].querySelectorAll("span");

        if (childs[0].querySelector("input").checked) {
          childs[0].classList.remove("Mui-checked");
          childs[0].querySelector("input").checked = false;
          form["you_are"].push(childs[2].innerHTML);
        }
      }

      const you_are_interested_in = document.querySelectorAll(
        ".you_are_interested_in label"
      );
      form["you_are_interested_in"] = [];
      for (let i = 0; i < you_are_interested_in.length; i++) {
        childs = you_are_interested_in[i].querySelectorAll("span");

        if (childs[0].querySelector("input").checked) {
          childs[0].classList.remove("Mui-checked");
          form["you_are_interested_in"].push(
            childs[3].innerHTML + childs[4].innerHTML
          );
        }
        childs[0].querySelector("input").checked = false;
      }

      // console.log(form);
      Services.get("user").then(([userService]) => {
        userService
          .inquiry(form)
          .then(() => {
            this.resetForm();
          })
          .catch((err) => {
            console.log("error", err);
          });
      });
    }
  }
}
