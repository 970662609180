import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/cannabreeze_info";

export default class CannaBreezeInfo extends Page {
  id = "cannabreeze-info";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setWindowTitle("CannaBreeze | Breeze Collective");

    this.getComponents()
      .findById("video")
      .forEach((el) => {
        el.showVideo();
      });

    this.scrollToTop();
  }
}
