import Helpers from "../../../modules/Core/Helpers/Helpers";
import BreezeCollectiveApi from "../../Api/BreezeCollective";
import Service from "../Service";

class User extends Service {
  inquiry(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      BreezeCollectiveApi.inquiry(account, repository, data).then((r) => {
        const result = this.evaluate(r, "contact");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new User();
