import React from "react";
import Box from "@mui/material/Box";
import FirstSection from "../widgets/cannabreeze/info";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import Slider from "../../../Components/Widgets/Slider";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "rgb(20, 20, 22 ,0.6)",
        overflow: "hidden",
      }}
    >
      <MenuDrawer {...props} />
      <NavBar
        {...{
          ...props,
          ...{ color: "#009444", position: "fixed" },
        }}
      />
      <FirstSection {...props} />
      <Slider
        {...{
          ...props,
          ...{ page: "canna" },
        }}
      />
    </Box>
  );
};

export default ExportDefault;
