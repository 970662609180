import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";

const StyledDiv = styled("div")(({ theme }) => ({
  margin: "0 auto",
  maxWidth: 1050,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 60,
  fontWeight: 400,
  fontFamily: "Gotham ,Arial, Helvetica, sans-serif",
  color: "#fff",
  textTransform: "uppercase",
  textAlign: "center",
  "@media (max-width: 1050px )": {
    fontSize: 50,
  },
  "@media (max-width: 885px )": {
    fontSize: 40,
  },
  "@media (max-width: 600px )": {
    fontSize: 30,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  float: "right",
  borderRadius: 50,
  width: 180,
  fontSize: 16,
  fontWeight: 300,
  height: 46,
  color: "#fff",
  margin: "20px 10px",
  backgroundColor: "rgb(20, 20, 22 ,0.8)",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgb(20, 20, 22 ,1)",
  },
  "@media (max-width: 600px )": { margin: "10px 5px", width: 130 },
}));

export default function FirstSection(props) {
  const { component } = props;
  const { showButtons = false } = component.getData("default", {});

  return (
    <StyledDiv>
      <Fade in={true} timeout={1000}>
        <Title>{component.trans("home-title")}</Title>
      </Fade>
      <Fade in={showButtons} timeout={2000}>
        <Grid container justifyContent="center" spacing={0}>
          <div>
            <StyledButton component={RouterLink} to="/cannabreeze">
              <span style={{ color: "#009444" }}>Canna</span>
              <span style={{ color: "#B6C9D2" }}>BREEZE</span>
            </StyledButton>
            <StyledButton component={RouterLink} to="/agrobreeze">
              <span style={{ color: "#B78140" }}>Agro</span>
              <span style={{ color: "#B6C9D2" }}>BREEZE</span>
            </StyledButton>
          </div>
          <div>
            <StyledButton component={RouterLink} to="/techbreeze">
              <span style={{ color: "#B3B2B7" }}>Tech</span>
              <span style={{ color: "#B6C9D2" }}>BREEZE</span>
            </StyledButton>
            <StyledButton component={RouterLink} to="/pharmabreeze">
              <span style={{ color: "#8DC63F" }}>Pharma</span>
              <span style={{ color: "#B6C9D2" }}>BREEZE</span>
            </StyledButton>
          </div>
        </Grid>
      </Fade>
    </StyledDiv>
  );
}
